body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .slick-arrow.slick-prev,.slick-arrow.slick-next{
  border: 4px
   solid;
      display: block;
      border-color: black black transparent transparent;
      background: transparent !important;
      height: 24px ;
      width: 24px;
      margin: 0 auto;
      transform: rotate(
  -51deg);
  transform-origin: 16px 8px;
}
.slick-arrow.slick-next{
  transform: rotate(
    -231deg);
} */