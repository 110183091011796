.charts {
  padding: 50px;
}

.charts .filter-header {
  background: white;
  padding: 15px;
  margin-bottom: 40px !important;
  border-radius: 4px;
}

.charts h1 {
  color: #e50041;
  font-weight: bold;
  font-size: 25px;
}

.wrapper p {
  font-size: 16px;
}

.charts .wrapper {
  background: #8861e0;
  color: white;
  text-align: center;
  border-radius: 8px;
  position: relative;
}

.charts .wrapper.green {
  background: #1ed897;
}

.charts .wrapper.blue {
  background: #2196f3;
}

.charts .wrapper.yellow {
  background: #ffaf02;
}

.card-box {
  background: white;
  box-shadow: 0px 0px 20px 0px rgb(172 167 167 / 15%);
}

.card-box .text {
  font-weight: 500;
  padding: 25px 15px;
  font-size: 23px;
  border-bottom: 1px solid #e8e2e2;
}

.products {
  list-style: none;
  padding: 20px;
}

.products li {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px dashed #e8e3e3;
  padding-bottom: 10px;
  padding-top: 10px;
}

.products li .image {
  width: 129px;
  height: 86px;
  border: 1px solid #c2c2c2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.products li .data {
  padding-left: 14px;
  flex: 1;
}

.products li .data p,
.products li .data span {
  color: rgb(150 150 150);
}
.products li:last-child {
  border: none;
}

.wrapper .icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.products li .data h2 {
  margin: 0;
  font-size: 15px;
  margin-bottom: 6px;
}

.products li .image img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.table-sold tbody tr:nth-child(odd) {
  background-color: #e4e4e4;
}

@media (max-width: 991px) {
  .charts {
    padding: 15px;
  }
}
