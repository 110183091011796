.product-style-1 {
  transition: 0.3s ease-in;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.product-style-1 {
  background-color: #fff;
  border: 1px solid #0000000a;
  border-radius: 14px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.badge-topright {
  font-weight: 700;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  right: 10px;
  top: 10px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.12941176470588237);
}
.discountBadge {
  padding: 3px 5px;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: none;
}
.product-style-1 .product-image {
  position: relative;
}
.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product-style-1 .product-content {
  position: static;
}

.product-style-1 .product-content {
  margin: 0;
  position: relative;
  z-index: 5;
  padding: 12px 2px 16px;
  text-transform: capitalize;
}
.product-style-1 .product-content p {
  color: #000;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 8px;
}
.wishlist {
  opacity: 0;
  transition: 0.7s ease;
  padding: 9px;
  position: absolute;
  width: 100%;
  border: 1px solid #c1c1c1;
  left: 0;
  z-index: 1000;
  cursor: pointer;
  background-color: #fff !important;
  bottom: 135px;
}
.cardTitle {
  height: 60px !important;
}

.product-style-1 .product-content .title a,
.product-style-1 .product-content .title a:hover {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #e50142 !important;
}

.text-primary,
.text-primary:hover {
  color: #e50142 !important;
}

.primary-btn,
.primary-btn:hover {
  background: rgb(229, 0, 65);
  background: linear-gradient(
    207deg,
    rgba(229, 0, 65, 1) 0%,
    rgba(229, 0, 65, 1) 25%,
    rgba(0, 121, 190, 1) 100%
  );
  color: #fff;
}

@media (max-width: 767px) {
  .product-style-1 .product-content .title a {
    font-size: 18px;
  }
  .product-style-1 .product-content {
    padding: 20px 10px 10px;
  }
}
.forMobile,
.forMobileLogin {
  display: none;
}
